<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">USP Users Maintenance</h3>
      <div>
        <el-button icon="el-icon-user" @click="drawer = true">Search Filter</el-button>
        <el-button  size="large" type="primary" @click="getRegisterUser">Register User</el-button>
      </div>
    </div>
    <el-drawer title="Search Filter" :visible.sync="drawer" direction="btt" size="50%">
      <div class="drawer">
        <el-form :ref="form" label-width="100px" label-position="left">
          <el-form-item label="Type">
            <el-select>
              <el-option></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Employee">
            <el-select v-model="em_id"  placeholder="Select  Employee" filterable>
              <el-option label="All" :value="0"></el-option>
              <el-option v-for="employee in employees" :key="employee.id" :value="employee.id" :label="employee.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="Edit" :visible.sync="editUspUserDialog">
      <el-form ref="edituspuserForm" :model="edituspuserForm" label-width="100px">
        <el-form-item label="Employee ID No.">
          <el-input v-model="edituspuserForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="Name">
          <el-input v-model="edituspuserForm.em_id_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="USP Email">
          <el-input v-model="edituspuserForm.emailadd_gmail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUspUserDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateUspUser">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580">
      <el-table-column label="EMPID" prop="username"></el-table-column>
      <el-table-column label="NAME" prop="em_id_name"></el-table-column>
      <el-table-column label="EMAIL ADDRESS" prop="emailadd_gmail"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editUspUser(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <div class="controls">
      <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div>
      <div class="control">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
          <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
        </el-button-group>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'UspUserMaintenance',
  data () {
    return {
      data: [],
      usp_user: [],
      employees: [],
      em_id: 0,
      items: 5,
      page: 1,
      drawer: false,
      hasNextPage: false,
      hasPreviousPage: false,
      dialogAccessModule: false,
      editUspUserDialog: false,
      edituspuserForm: {
        id: null,
        username: null,
        em_id_name: null,
        emailadd_gmail: null,
        suag_id: null,
        is_force_disabled: null,
        remarks_force_disabled: null
      }
    }
  },
  watch: {
    items () {
      this.getUserList()
    }
  },
  mounted () {
    this.getUserList()
    this.getEmployees()
  },
  methods: {
    getEmployees () {
      this.$http
        .get('get.employee-list/0')
        .then(res => {
          this.employees = res.data.body
        })
    },
    getRegisterUser () {
      // this.$http
      //   .get('sysadmin.systemaccess/register-user')
      //   .then(res => {
      //     this.$message({
      //       message: 'User Registration successfully updated',
      //       type: 'success'
      //     })
      //     this.getUserList()
      //   })
    },
    getUserList () {
      this.$http
        .get(`sysadmin.systemaccess/user/${this.em_id}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getUserList()
    },
    prevPage () {
      this.page--
      this.getUserList()
    },
    editUspUser (row) {
      this.editUspUserDialog = true
      this.edituspuserForm.id = row.id
      this.edituspuserForm.suag_id = row.suag_id
      this.edituspuserForm.username = row.username
      this.edituspuserForm.em_id_name = row.em_id_name
      this.edituspuserForm.emailadd_gmail = row.emailadd_gmail
    },
    updateUspUser () {
      this.editUspUserDialog = false
      this.$http
        .post('sysadmin.systemaccess/user-update-email', this.edituspuserForm)
        .then(() => {
          this.$message({
            message: 'Email successfully updated',
            type: 'success'
          })
          this.getUserList()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    confirmFilterDialog () {
      this.drawer = false
      this.getUserList()
    },
    closeFilterDialog () {
      this.drawer = false
      this.em_id = 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
